<template>
	<div class="col-12">
		<div class="card">
			<h5>Preço da Integrada</h5>
				<DataTable :value="precosIntegrada" :paginator="true" class="p-datatable-gridlines" style="text-align: center" :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="stack"
					:globalFilterFields="['descricaoCompleta']" >
					<!-- :globalFilterFields="['descricaoCompleta','data','horaFinal','valor','valorPorQuilo']" > -->
				<template #header>
					<div class="flex justify-content-between flex-column sm:flex-row">
						<Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2" @click="clearFilter1()"/>
						<span class="p-input-icon-left mb-2">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="Procurar por Produto" style="width: 100%"/>
						</span>
					</div>
				</template>
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Carregando... Por favor, aguarde.
				</template>
				<Column field="descricaoCompleta" header="Produto" style="text-align: left; min-width:12rem">
					<template>
						{{descricaoCompleta}}
					</template>
				</Column>
				<Column field="data" header="Data" style="text-align: center; min-width:12rem">
					<template #body="{data}">
						{{formatDate(data.data)}}
					</template>
				</Column>
				<Column field="horaFinal" header="Hora Validade" style="text-align: center; min-width:12rem">
					<template >
						{{horaFinal}}
					</template>
				</Column>
				<Column field="valor" header="Valor" style="text-align: right; min-width:12rem">
					<template #body="{data}">
						<div style="display: inline-flex; vertical-align: middle;">
							<span style="" class="text-bold">{{formatCurrency(data.valor)}}</span>
						</div>
						<div style="display: inline-flex; vertical-align: middle;">
							<Button type="button" icon="pi pi-bell" class="p-button ml-3" @click="alertaPreco(data.codigoGrupo, data.codigoReferencia)" style="font-size: 1rem" />
						</div>
					</template>
					
				</Column>
				<Column field="valorPorQuilo" header="Valor/Kg" style="text-align: right; min-width:12rem">
					<template  #body="{data}">
						<span class="text-bold">{{formatCurrency(data.valorPorQuilo)}}</span>
					</template>
				</Column>	

				<Column field="codigoReferencia" header="Temporario" style="text-align: left; min-width:12rem" v-if="false">
					<template>
						{{codigoReferencia}}
					</template>
				</Column>

			</DataTable>
		</div>
	</div>
	

	<Dialog v-model:visible="alertaPrecoDialog" :style="{width: '380px'}" header="Alerta de Preço" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <span class="text-800 text-lg">Indique o valor desejado do preço e quando ele for atingido você será avisado!</span>
        </div>

		<hr/>

		<div  class="flex align-items-start">
			<div class="col-12">  
                <label for="alerta-perco"><strong>Preço Alerta: </strong></label>
				<div class="inline-flex" style="background-color: aqua;">
					<InputNumber v-model="precoAlertaModal" class="w-full" inputId="alerta-preco" mode="currency" currency="BRL" locale="pt-BR" />
					<Button v-if="isEdicao" type="button" icon="pi pi-trash" class="p-button-danger" @click="excluirAlertaPreco()"/>
				</div>
            </div>
		</div>

		<hr/>

		<span class="text-600 text-sm">O aviso será enviado por notificação em seu celular e também por e-mail.</span>

        <template #footer>
            <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="salvarAlertaPreco()" />
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text p-button-danger" @click="alertaPrecoDialog = false;" />
        </template>
    </Dialog>


</template>

<script>
	import {FilterMatchMode,FilterOperator} from 'primevue/api';
    import Formatacao from '../../utilities/Formatacao';
    import PrecosIntegradaService from "../../service/PrecosIntegradaService.js"
	import AlertaPrecoPonto from "../../service/AlertaPrecoPonto.js"
	export default {
		props: {
			matricula: {
				type: Number,
				required: true
			}
		},
		data() {
			return {
				filters1: null,
				filters2: {},
				loading1: true,
				products: null,
                precosIntegrada: [],
				alertaPrecoDialog:false,
				precoAlerta:"",
				precoAlertaModal: 0,
				matriculaAlertaPreco: null,
				isEdicao:false,
				codigoGrupo: null,
			}
		},

		created() {
			this.initFilters1();
            this.getPrecosIntegrada();
		},
		mounted() {
			this.loading1 = false;
		},
		methods: {
			initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'descricaoCompleta': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'horaFinal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'data': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
					'valor': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'valorPorQuilo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				
				}
			},
            getPrecosIntegrada() {
                PrecosIntegradaService.getPrecosIntegrada(this.$props.matricula).then((response) => {
                    this.precosIntegrada = response.data.todosPontos;
					//console.log("Preço Integrada: ", this.precosIntegrada);
                    
                });
            },
			clearFilter1() {
				this.initFilters1();
			},

			formatDate(data) {                
               return Formatacao.formatDate(data);
			},

			formatCurrency(value) {
				return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
			},

			//Abrir a modal
			alertaPreco(codigoGrupo, referencia){
				
				this.matriculaAlertaPreco = this.$store.state.auth.user.matricula;
				this.codigoGrupo = codigoGrupo;
				this.referencia = referencia;

				//consulta se o cooperado possui já algum alerta de preço
				//this.pesquisarPrecoAlerta(this.matriculaAlertaPreco, codigoGrupo);

				AlertaPrecoPonto.consultarPrecoAlerta(this.matriculaAlertaPreco, codigoGrupo, referencia)
					.then(({ data }) => {
						if (data) {
							this.precoAlerta = data.Retorno[0];

							console.log('sucess', this.precoAlerta.sucess);
							console.log('Ativo', this.precoAlerta.Ativo);
							console.log('preco', this.precoAlerta.preco);
							
							//Verifica se é edicao
							if(this.precoAlerta.sucess==true && this.precoAlerta.Ativo==true){
								console.log("Entrou!");
								//Trata-se de uma inclusã de um novo valor.
								this.isEdicao = true; //Desabilitar botão Excluir
								this.precoAlertaModal = this.precoAlerta.preco;

							}
							else{
								
								console.log("Passou no else");

								//Trata-se de uma alteração do preço, Usuário pode excluir o alerta do preço
								this.isEdicao = false; //Habilita botão Excluir
								this.precoAlertaModal = 0;
							}

							this.alertaPrecoDialog = true; //Habilita o modal para 

						}
					})
					.catch(error => {
							this.$toast.add({
								severity:"error",
								detail: "Ocorreu um erro ao consultar o preço alerta. Por favor, tente novamente.", 
								life: 3000
							});
							console.error(error);
							this.alertaPrecoDialog = false; 
					});
			},

			salvarAlertaPreco(){
				this.matriculaAlertaPreco = this.$store.state.auth.user.matricula;

				let precoAlertaCadastro = {
					"alertaPrecoProdutor" : [
						{
							codigoProdutor: this.matriculaAlertaPreco,
            				codigoGrupo: this.codigoGrupo,
            				referencia: this.referencia,
            				preco: this.precoAlertaModal,
            				Ativo: true,
						}
					]
				}

				if(!this.isEdicao){

					AlertaPrecoPonto.consultarPrecoAlerta(this.matriculaAlertaPreco, this.codigoGrupo, this.referencia)
					.then(({ data }) => {
						if (data) {
							this.precoAlerta = data.Retorno[0];

							

							if(this.precoAlerta.sucess == true && !this.alertaPreco.Ativo){
								//chamar para editar alerta o preço pois ele foi excluido anteriormente
								AlertaPrecoPonto.alterarPrecoAlerta(precoAlertaCadastro)
									.then(() => {
										this.$toast.add({
											severity:'success',
											detail:'O alerta de preço foi salvo!', 
											life: 3000
										});
									})
									.catch(error => {
										this.$toast.add({
											severity:"error",
											detail: "Ocorreu um erro ao editar o preço alerta. Por favor, tente novamente.", 
											life: 3000
										});
										console.error(error);
									})
									.finally(() => {
										this.precoAlerta = null;
										this.precoInclusao = true;		                       
										this.alertaPrecoDialog = false; //Fecha a Modal
									});
							}
							else
							{
								//chamar para salvar alerta o preço
								AlertaPrecoPonto.cadastrarPrecoAlerta(precoAlertaCadastro)
								.then(({ data }) => {

										if (data) {

											console.log("Retorno:", data.Retorno[0]);

											this.precoAlerta = data.Retorno[0];

											console.log('!sucess', this.precoAlerta.sucess);
											console.log('!Ativo', this.precoAlerta.Ativo);
											console.log('!preco', this.precoAlerta.preco);
											console.log('!preco', this.precoAlerta.message);
											
											//Verifica se é edicao
											if(this.precoAlerta.sucess==true){

												this.$toast.add({
													severity:'success',
													detail:'O alerta de preço foi salvo!', 
													life: 3000
												});
											} else {
												this.$toast.add({
												severity:"error",
												detail: "Ocorreu um erro ao salvar o preço alerta. Por favor, tente novamente.#", 
												life: 3000
												});		
											}
										}
									})
									.catch(error => {
										this.$toast.add({
											severity:"error",
											detail: "Ocorreu um erro ao salvar o preço alerta. Por favor, tente novamente.", 
											life: 3000
										});
										console.error(error);
									})
									.finally(() => {
										this.precoAlerta = null;
										this.precoInclusao = true;		                       
										this.alertaPrecoDialog = false; //Fecha a Modal
									});
							}
						}
					});


					
				}else{

					//chamar para editar alerta o preço
					AlertaPrecoPonto.alterarPrecoAlerta(precoAlertaCadastro)
						.then(() => {
							this.$toast.add({
								severity:'success',
								detail:'O alerta de preço foi salvo!', 
								life: 3000
							});
						})
						.catch(error => {
							this.$toast.add({
								severity:"error",
								detail: "Ocorreu um erro ao editar o preço alerta. Por favor, tente novamente.", 
								life: 3000
							});
							console.error(error);
						})
						.finally(() => {
							this.precoAlerta = null;
							this.precoInclusao = true;		                       
							this.alertaPrecoDialog = false; //Fecha a Modal
						});

				}
			},

			pesquisarPrecoAlerta(matricula, codigoGrupo){
				//Pesquisa se o cooperado cadastrou algum valor para o codGrupo.
				AlertaPrecoPonto.consultarPrecoAlerta(matricula, codigoGrupo)
					.then(({ data }) => {
						if (data) {
							this.precoAlerta = data.valorAlerta
						}
					});
			},

			excluirAlertaPreco(){
				this.matriculaAlertaPreco = this.$store.state.auth.user.matricula;

				let precoAlertaCadastro = {
					"alertaPrecoProdutor" : [
						{
							codigoProdutor: this.matriculaAlertaPreco,
            				codigoGrupo: this.codigoGrupo,
            				referencia: this.referencia,
            				preco: this.precoAlertaModal,
            				Ativo: false, //IMPORTANTE! Para "Deletar" o alerta preço, é necessário mudar o ativo de true para false.
						}
					]
				}
			
				//chamar para editar alerta o preço
				AlertaPrecoPonto.deletarPrecoAlerta(precoAlertaCadastro)
					.then(() => {
						this.$toast.add({
							severity:'success',
							detail:'O alerta de preço foi excluído!', 
							life: 3000
						});
					})
					.catch(error => {
						this.$toast.add({
							severity:"error",
							detail: "Ocorreu um erro ao excluir o alerta de preço. Por favor, tente novamente.", 
							life: 3000
						});
						console.error(error);
					})
					.finally(() => {
						this.precoAlerta = null;
						this.precoInclusao = true;
						this.alertaPrecoDialog = false; //Fecha a Modal
					});
				
			}
		}
	}
</script>

<style>
    @import '../../assets/demo/styles/badges.scss';

	::v-deep(.p-datatable-frozen-tbody) {
		font-weight: bold;
	}

	::v-deep(.p-datatable-scrollable .p-frozen-column) {
		font-weight: bold;
	}

	::v-deep(.p-progressbar) {
		height: .5rem;
		background-color: #D8DADC;
	}

	.p-progressbar-value {
		background-color: #607D8B;
	}
	.p-datatable-table .p-column-header-content {
        display: inline-flex !important;
    }
</style>