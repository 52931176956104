import axios from 'axios';
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperadoService;
const RESOURCE_V1 = `/v1/preco-integrada`;

class PrecosIntegradaService {

    getPrecosIntegrada(matricula){
        return axios.get(`${ API_BASE_URL }${RESOURCE_V1}/${matricula}`);
    }

}

export default new PrecosIntegradaService();