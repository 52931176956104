<template>
    <PrecosIntegrada :matricula="currentUser.matricula"/>
</template>

<script lang="js">
    import PrecosIntegrada from '../../components/consulta/PrecosIntegrada.vue'

    export  default {
		computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: {PrecosIntegrada }
    }

</script>