import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/notificacao-preco-ponto`;

class AlertaPrecoPonto {

    consultarPrecoAlerta(matricula, codigoGrupo, referencia) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/${matricula}/${codigoGrupo}?referencia=${referencia}`);
    }

    cadastrarPrecoAlerta(precoAlerta) {
        return axios.post(`${API_BASE_URL}${RESOURCE_V1}`, precoAlerta);
    }

    alterarPrecoAlerta(precoAlerta) {
        return axios.put(`${API_BASE_URL}${RESOURCE_V1}`, precoAlerta);
    }

    deletarPrecoAlerta(precoAlerta) {
        return axios.put(`${API_BASE_URL}${RESOURCE_V1}/deletar`, precoAlerta);
    }

}

export default new AlertaPrecoPonto();